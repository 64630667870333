exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-enlaces-de-interes-politica-privacidad-js": () => import("./../../../src/pages/enlaces-de-interes/politica-privacidad.js" /* webpackChunkName: "component---src-pages-enlaces-de-interes-politica-privacidad-js" */),
  "component---src-pages-enlaces-de-interes-pqrs-js": () => import("./../../../src/pages/enlaces-de-interes/pqrs.js" /* webpackChunkName: "component---src-pages-enlaces-de-interes-pqrs-js" */),
  "component---src-pages-enlaces-de-interes-preguntas-frecuentes-js": () => import("./../../../src/pages/enlaces-de-interes/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-enlaces-de-interes-preguntas-frecuentes-js" */),
  "component---src-pages-enlaces-de-interes-rezagos-js": () => import("./../../../src/pages/enlaces-de-interes/rezagos.js" /* webpackChunkName: "component---src-pages-enlaces-de-interes-rezagos-js" */),
  "component---src-pages-enlaces-de-interes-terminos-condiciones-js": () => import("./../../../src/pages/enlaces-de-interes/terminos-condiciones.js" /* webpackChunkName: "component---src-pages-enlaces-de-interes-terminos-condiciones-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-personas-casillero-virtual-js": () => import("./../../../src/pages/personas/casillero-virtual.js" /* webpackChunkName: "component---src-pages-personas-casillero-virtual-js" */),
  "component---src-pages-personas-envios-colombia-usa-js": () => import("./../../../src/pages/personas/envios-colombia-usa.js" /* webpackChunkName: "component---src-pages-personas-envios-colombia-usa-js" */),
  "component---src-pages-personas-envios-usa-colombia-js": () => import("./../../../src/pages/personas/envios-usa-colombia.js" /* webpackChunkName: "component---src-pages-personas-envios-usa-colombia-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

